<template>
  <div style="text-align: left;">
    <div>
      <h1 style="color: black;"> Privacy Policy for Educational Service Unit Coordinating Council (ESUCC)</h1>
      <p>The Educational Service Unit Coordinating Council (ESUCC) informational site is accessible via <a
          href="https://www.esucc.org/">https://www.esucc.org/</a>. The privacy of our visitors is a priority. This
        Privacy Policy document contains types of information that is collected and recorded by the ESUCC and how we use
        it.</p>
      <p>If you have additional questions or require more information about our Privacy Policy please contact us. Our
        Privacy Policy was created with the help of the <a href="https://www.privacypolicygenerator.info/">Free Privacy
          Policy Generator</a>.</p>
      <h2 style="color: black;"> Consent</h2>
      <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
      <h2 style="color: black;"> Information we collect</h2>
      <p>The information you are asked to provide and the reasons why you are asked to provide it will be clearly stated
        at the points we ask for the information. By providing us with the requested information, you agree to provide
        us
        with the information for the stated purpose.</p>
      <p>If you contact us directly, we may receive additional information about you such as your name, email address,
        phone number, the contents of the message and/or attachments you may send us, and any other information you may
        choose to provide.</p>
      <p>When you register for an account with any of our online sites or applications, we may ask for your contact
        information, including items such as name, company name, address, email address, and telephone number.</p>
      <h2 style="color: black;"> How we use your information</h2>
      <p>We use the information we collect in various ways, including to:</p>
      <ul>
        <li>
          <p>Provide, operate, and maintain our websites and applications</p>
        </li>
        <li>
          <p>Improve, personalize, and expand our websites and applications</p>
        </li>
        <li>
          <p>Understand and analyze how you use our websites and applications</p>
        </li>
        <li>
          <p>Develop new products, services, features, and functionality</p>
        </li>
        <li>
          <p>Communicate with you, either directly or through one of our partners, including for customer service, to
            provide you with updates and other information relating to the website, and for marketing and promotional
            purposes</p>
        </li>
        <li>
          <p>Send you emails</p>
        </li>
        <li>
          <p>Find and prevent fraud</p>
        </li>
      </ul>
      <h2 style="color: black;"> Log Files</h2>
      <p>The ESUCC follows a standard procedure of using log files. These files log visitors when they visit websites.
        All
        hosting companies do this and it is a common practice as part of hosting services' analytics. The information
        collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP),
        date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any
        information that is personally identifiable. The purpose of the information is for analyzing trends,
        administering
        the site, tracking users' movement on the website, and gathering demographic information.</p>
      <h2 style="color: black;"> Cookies and Web Beacons</h2>
      <p>Like any other website, the ESUCC uses 'cookies'. These cookies are used to store information including
        visitors'
        preferences, and the pages on the website that the visitor accessed or visited. The information is used to
        optimize the users' experience by customizing our web page content based on visitors' browser type and/or other
        information.</p>
      <h2 style="color: black;"> Third Party Privacy Policies</h2>
      <p>The ESUCC's Privacy Policy does not apply to our business partners or other linked websites. Thus, we are
        advising you to consult the respective Privacy Policies of any sites linked from the ESUSS websites or
        applications for more detailed information on their privacy policies and practices. We have no control over (and
        cannot prevent) any third-party entities from collecting information or accessing data, and the ESUCC makes no
        representations or warranties of any kind regarding any third-party's privacy policies and/or practices.</p>
      <p>You may choose to disable cookies through your individual browser options. To know more detailed information
        about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>
      <h2 style="color: black;"> Children's Information</h2>
      <p>We encourage parents and guardians to observe, participate in, monitor and guide their online activity.</p>
      <p>The ESUCC does not knowingly collect any Personal Identifiable Information from children under the age of 13.
        If
        you think that your child provided this kind of information on our website, we encourage you to contact us and
        we
        will work with you to locate and remove any such information collected.</p>
    </div>
    <br>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy"
}
</script>

<style scoped>
h1, h2 {
  color: black;
}
</style>